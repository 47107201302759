<script>

export default {
  name: 'StatsPreview',
  props: {
    // hardcodded: { required: false, type: Boolean, default: false },
    salles: { required: false, type: Array, default: () => [] },
    usagers: { required: false, type: Array, defualt: () => [] },
    batches: { required: false, type: Array, default: () => [] },
    lineGraph: { required: false, type: Boolean, default: false },
    // graphData: { required: false, type: Array, default: () => [0, 100, 40, 35, 87, 85, 70, 73, 90] },
    devoirs: { required: false, type: Array, default: () => [] }
  },
  computed: {
    hasFirstStatsSlot () {
      return !!this.$slots['numberStatsFirstRow']
    },
    hasSecondStatsSlot () {
      return !!this.$slots['numberStatsSecondRow']
    }
    // activePeriode () {
    //   return this.$store.state.Exercices.periodeActive
    // }
  },
  data () {
    return {
      moyenne: null,
      loading: true,
      graphDataCalc: [],
      graphDataLabels: [],
      graph: {
        width: 3,
        radius: 10,
        padding: 8,
        lineCap: 'round',
        gradient: ['green', 'yellow', 'red', 'black'],
        value: [0, 100, 40, 35, 87, 85, 70, 73, 90],
        gradientDirection: 'top',
        fill: false,
        type: 'trend',
        autoLineWidth: true
      }
    }
  },
  beforeDestroy () {
    this.$store.commit('Exercices/setPeriodeActive', null)
  },
  apollo: {
    moyenne: {
      query: require('@/graphql/queries/v2/moyenneCalendrierCalendrierElements.gql'),
      variables () {
        const variables = {}
        if (this.batches.length > 0) {
          variables.batches = this.batches
        }
        if (this.salles.length > 0) {
          variables.salles = this.salles
        }
        if (this.activePeriode) {
          // variables.debut = this.activePeriode.debut
          variables.fin = [this.periodeActive.debut, this.periodeActive.fin]
          if (this.activePeriode.type === 1) {
            // semaine
            variables.periode = 'JOUR'
          } else if (this.activePeriode.type === 2) {
            variables.periode = 'SEMAINE'
          } else {
            variables.periode = 'MOIS'
          }
        }
        if (!variables.periode) {
          variables.periode = 'MOIS'
        }
        return variables
      },
      update (data) {
        this.loading = false
        this.graphDataLabels = []
        this.graphDataCalc = []
        data.moyenneCalendrierCalendrierElements.historique.forEach((entree) => {
          if (!this.activePeriode || (this.activePeriode && this.activePeriode.type > 2)) {
            this.graphDataLabels.push(new Date(entree.fin).toLocaleString('fr-FR', { month: 'short' }))
          } else if (this.activePeriode && this.activePeriode.type <= 2) {
            this.graphDataLabels.push(new Date(entree.fin).getDate())
          }
          this.graphDataCalc.push(entree.total ? entree.total : 0)
        })
        return data.moyenneCalendrierCalendrierElements.total
      },
      watchLoading (isLoading) {
        this.loading = isLoading
      },
      skip () {
        return (!this.batches || !this.salles || !this.activePeriode)
      }
    }
  }
}
</script>

<template lang='pug'>
v-card.stats-preview.secondary--text(:color='$store.state.Preferences.darkMode ? "light" : "white"')
  div.pa2.flex(v-if='lineGraph && graphDataCalc')
    //- div.flex-grow-1
    //- v-select(v-model='selectedTimeFilter', :items='timeFilters', item-text='titre', item-value='value', placeholder='Filtrer par période', dense, hide-details, clearable)
  .stats__wrapper(v-if='hasFirstStatsSlot')
    slot(name='numberStatsFirstRow')
  .stats__wrapper(v-if='hasSecondStatsSlot')
    slot(name='numberStatsSecondRow')
    //- div.pa2.tc.flex-grow-1
    //-   div.f2.b(style='font-family: "Source Serif Pro", serif;') 80%
    //-   div.f5.i Moyenne Générale
    //- v-divider(vertical)
    //- div.pa2.tc.flex-grow-1
    //-   div.f2.b(style='font-family: "Source Serif Pro", serif;') 22
    //-   div.f5.i Devoirs
    //- v-divider(vertical)
    //- div.pa2.tc.flex-grow-1
    //-   div.f2.b(style='font-family: "Source Serif Pro", serif;') 18
    //-   div.f5.i Élèves
  template(v-if='lineGraph && graphDataCalc')
    v-divider
    v-sparkline(
      v-if='!loading'
      :value='graphDataCalc'
      :labels='graphDataLabels'
      :gradient='graph.gradient'
      :smooth='graph.radius || false'
      :padding='graph.padding'
      :line-width='graph.width'
      :stroke-linecap='graph.lineCap'
      :gradient-direction='graph.gradientDirection'
      :fill='graph.fill'
      :type='graph.type'
      :auto-line-width='graph.autoLineWidth'
      auto-draw='',
      show-labels
      :color='$store.state.Preferences.darkMode ? "secondary" : "primary"'
    )
</template>
<style lang='sass'>
  @import 'src/styles/components/statistiques/_statsPreview'

</style>
